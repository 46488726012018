<template>
  <v-container @click="clicked" class="form-servizio-clienti mb-6">
    <v-form ref="formRef" v-if="form != null" v-model="valid">
      <v-row>
        <v-col cols="12">
          <h3 class="form-title">
            Inserisci i tuoi dati e invia la tua richiesta:
          </h3>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="form.fields['19'].label"
            v-model="form.fields['19'].value"
            dense
            outlined
            :rules="[requiredRule()]"
            required
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="form.fields['20'].label"
            v-model="form.fields['20'].value"
            outlined
            dense
            :rules="[requiredRule()]"
            required
          >
          </v-text-field
        ></v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="form.fields['21'].label"
            v-model="form.fields['21'].value"
            outlined
            dense
            :rules="emailRules"
            required
          >
          </v-text-field
        ></v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="form.fields['22'].label"
            v-model="form.fields['22'].value"
            outlined
            dense
            :rules="mobilePhoneRules"
          >
          </v-text-field
        ></v-col>
        <v-col cols="12">
          <h3>Come possiamo aiutarti?</h3>
        </v-col>
        <v-col cols="12">
          <v-textarea
            :label="form.fields['23'].label"
            v-model="form.fields['23'].value"
            outlined
          >
          </v-textarea>
        </v-col>
        <div class="legal-agreement">
          <div v-if="showPrivacy">
            <v-col cols="12" v-if="!warehouseEmail">
              <v-checkbox
                :label="form.fields['25'].label"
                v-model="form.fields['25'].value"
                outlined
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" v-if="!warehouseEmail">
              <v-checkbox
                :label="form.fields['26'].label"
                v-model="form.fields['26'].value"
                outlined
              >
              </v-checkbox>
            </v-col>
          </div>
          <div v-else>
            <v-col cols="12">
              <div v-html="form.fields['40'].desc_text"></div>
              <v-checkbox
                :label="form.fields['40'].label"
                v-model="form.fields['40'].value"
                outlined
              >
              </v-checkbox>
            </v-col>
          </div>
        </div>
        <v-col cols="12" class="send-data-btn">
          <v-btn
            color="primary"
            depressed
            :disabled="!valid || (!showPrivacy && !form.fields['40'].value)"
            v-model="form.fields['24'].value"
            @click.stop.prevent="handleSubmit"
          >
            {{ form.fields["24"].label }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-alert type="success" v-if="formSent"
      >Grazie per averci inviato la tua richiesta</v-alert
    >
  </v-container>
</template>
<style lang="scss">
.form-servizio-clienti {
  .v-text-field {
    font-size: 14px;
    label {
      font-size: 14px;
    }
  }
  ::placeholder {
    font-size: 14px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-input {
      font-size: 12px;
      .v-label {
        font-size: 12px;
      }
    }
  }
  // .privacy {
  //   .v-input--selection-controls {
  //     margin-top: -8px;
  //     label {
  //       color: $text-color;
  //     }
  //   }
  //   span,
  //   label,
  //   p {
  //     font-size: 12px;
  //     line-height: 18px;
  //   }
  // }
}
.send-data-btn {
  text-align: left;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    text-align: center;
  }
}
</style>
<script>
import CMService from "~/service/cmService";

import clickHandler from "~/mixins/clickHandler";
import { mapGetters } from "vuex";
import {
  requiredValue,
  isEmail,
  isMobilePhone
} from "~/validator/validationRules";

export default {
  data() {
    return {
      // warehouseEmail: null,
      form: null,
      formSent: false,
      valid: true,
      requiredRule: requiredValue,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      mobilePhoneRules: [isMobilePhone()]
    };
  },
  props: {
    warehouseEmail: { type: String, default: null },
    showPrivacy: { type: Boolean, default: true }
  },
  mixins: [clickHandler],
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      user: "cart/getUser"
    })
  },
  methods: {
    async fetchForm(formId) {
      let _this = this;
      _this.form = await CMService.getForm(formId);
      if (_this.isAuthenticated) {
        _this.form.fields["19"].value = _this.user.firstName;
        _this.form.fields["20"].value = _this.user.lastName;
        _this.form.fields["21"].value = _this.user.email;
        _this.form.fields["22"].value =
          _this.user.billingAddress.referenceMobileNumber;
      }
      if (_this.warehouseEmail) {
        _this.form.fields["37"].value = _this.warehouseEmail;
      }
    },
    async handleSubmit() {
      if (this.$refs.formRef.validate()) {
        let result = await CMService.sendForm(this.form);
        if (result) {
          this.$emit("submit", true);
          this.resetFormField();
          this.form = null;
          this.formSent = true;
        }
      }
    },
    resetFormField() {
      this.form.fields["19"].value = "";
      this.form.fields["20"].value = "";
      this.form.fields["21"].value = "";
      this.form.fields["22"].value = "";
      this.form.fields["40"].value = false;
    }
  },
  mounted() {
    this.fetchForm("5");
  }
};
</script>
