<template>
  <div class="warehouse-detail">
    <Breadcrumb :items="breadCrumbs" />
    <div class="grey lighten-3 py-6">
      <v-container>
        <v-card elevation="3" :key="warehouse.warehouseId">
          <v-row>
            <v-col cols="12" sm="4" xl="3">
              <div class="pa-6 warehouse-detail-column">
                <h1>
                  <img src="/location.svg" alt="pin negozio" />{{
                    warehouse.name
                  }}
                </h1>
                <div class="mb-4">
                  <strong
                    >{{ warehouse.address.address1 }},
                    {{ warehouse.address.addressNumber }},
                    {{ warehouse.address.city }} - ({{
                      warehouse.address.province
                    }}) {{ warehouse.address.postalcode }}</strong
                  >
                </div>
                <div v-if="warehouse.metaData.warehouse_info">
                  <strong>Email: </strong>
                  <a
                    :href="'mailto:' + warehouse.metaData.warehouse_info.EMAIL"
                    >{{ warehouse.metaData.warehouse_info.EMAIL }}</a
                  >
                </div>
                <div v-if="warehouse.metaData.warehouse_info">
                  <strong>Email delivery: </strong>
                  <a
                    :href="
                      'mailto:' +
                        warehouse.metaData.warehouse_info.EMAIL_DELIVERY
                    "
                    >{{ warehouse.metaData.warehouse_info.EMAIL_DELIVERY }}</a
                  >
                </div>
                <div v-if="warehouse.metaData.warehouse_info">
                  <strong>Telefono: </strong>
                  <a :href="'tel:' + warehouse.metaData.warehouse_info.PHONE">{{
                    warehouse.metaData.warehouse_info.PHONE
                  }}</a>
                </div>

                <div
                  v-if="warehouse.metaData.warehouse_info"
                  v-html="warehouse.metaData.warehouse_info.SERVICES_DESCR"
                  @click="clicked"
                ></div>
                <div
                  v-if="warehouse.metaData.warehouse_info"
                  v-html="warehouse.metaData.warehouse_info.OPENING_TIMES"
                  @click="clicked"
                ></div>
                <h4>SERVIZI ATTIVI</h4>
                <div
                  class="d-flex flex-wrap"
                  v-if="warehouse.metaData.warehouse_service"
                >
                  <router-link
                    class="pa-3"
                    v-if="warehouse.metaData.warehouse_service.DELIVERY"
                    :to="{ name: 'Page', params: { pageName: 'delivery' } }"
                    title="Servizio Delivery attivo in questo punto vendita"
                  >
                    <img
                      src="https://www.grosmarket.it/p/wp-content/uploads/2021/06/Delivery-1-45x45.png"
                      alt="Icona Servizio Delivery"
                    />
                  </router-link>
                  <router-link
                    class="pa-3"
                    v-if="warehouse.metaData.warehouse_service.CASH_CARRY"
                    :to="{
                      name: 'Page',
                      params: { pageName: 'cash-and-carry' }
                    }"
                    title="Servizio Cash & Carry attivo in questo punto vendita"
                  >
                    <img
                      src="https://www.grosmarket.it/p/wp-content/uploads/2021/06/CashAndCarry-45x45.png"
                      alt="Icona Servizio Cash & Carry"
                    />
                  </router-link>
                  <router-link
                    class="pa-3"
                    v-if="warehouse.metaData.warehouse_service.CLICK_COLLECT"
                    :to="{ name: 'Page', params: { pageName: 'clickcollect' } }"
                    title="Servizio Click & Collect attivo in questo punto vendita"
                  >
                    <img
                      src="https://www.grosmarket.it/p/wp-content/uploads/2021/06/ClickAndCollect-1-45x45.png"
                      alt="Icona Servizio Click & Collect"
                    />
                  </router-link>
                  <router-link
                    class="pa-3"
                    v-if="warehouse.metaData.warehouse_service.BUY_GO"
                    :to="{ name: 'Page', params: { pageName: 'buy-and-go' } }"
                    title="Servizio Buy & Go attivo in questo punto vendita"
                  >
                    <img
                      src="https://www.grosmarket.it/p/wp-content/uploads/2021/06/BuyAndGo-45x45.png"
                      alt="Icona Servizio Click & Collect"
                    />
                  </router-link>
                  <router-link
                    class="pa-3"
                    v-if="warehouse.metaData.warehouse_service.HORECA"
                    :to="{ name: 'Page', params: { pageName: 'horeca' } }"
                    title="Servizio Horeca Account attivo in questo punto vendita"
                  >
                    <img
                      src="https://www.grosmarket.it/p/wp-content/uploads/2021/06/Horeca-1-45x45.png"
                      alt="Icona Servizio Horeca Account"
                    />
                  </router-link>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="8" xl="9">
              <GmapMap
                v-if="warehouse.warehouseId"
                ref="mapRef"
                :center="{
                  lat: warehouse.address.latitude,
                  lng: warehouse.address.longitude
                }"
                :zoom="15"
                :style="{ width: mapWidth, height: mapHeight }"
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUI: false
                }"
              >
              </GmapMap>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </div>
    <div class="">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6"> <v-img src="/img/contact-store.jpg"/></v-col>
          <v-col cols="12" sm="6">
            <form-servizio-clienti
              :showPrivacy="false"
              :warehouse-email="warehouse.metaData.warehouse_info.EMAIL"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<style lang="scss">
.warehouse-detail {
  .form-servizio-clienti {
    .form-title {
      color: var(--v-secondary-base) !important;
    }
  }
  .warehouse-list-list {
    height: 380px;
    overflow-y: auto;
  }
  .warehouse-detail-column {
    h1 {
      font-weight: 600;
      font-size: 22px;
      line-height: 150%;
      /* identical to box height, or 33px */
      letter-spacing: -0.02em;
      text-transform: uppercase;

      color: #18988b;
    }
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    color: #636363;
  }
}
</style>
<script>
import AddressService from "~/service/addressService";
import Breadcrumb from "@/components/navigation/Breadcrumb";
import FormServizioClienti from "@/components/wordpress/FormServizioClienti.vue";
import { gmapApi } from "vue2-google-maps";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "WarehouseDetail",
  components: { Breadcrumb, FormServizioClienti },
  data() {
    return {
      warehouseList: [],
      warehouse: {}
    };
  },
  mixins: [clickHandler],
  computed: {
    google: gmapApi,
    mapWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "351px";
        case "sm":
          return "573px";
        case "md":
          return "563px";
        case "lg":
          return "754px";
        case "xl":
          return "1061px";
      }
      return "850px";
    },
    mapHeight() {
      return "480px";
    },
    breadCrumbs() {
      let breadCrumbs = [
        { to: { name: "Home" }, text: "Homepage", exact: true },
        { to: { name: "WarehouseList" }, text: "Punti Vendita", exact: true }
      ];
      if (this.warehouse) {
        breadCrumbs.push({
          to: {
            name: "WarehouseDetail",
            params: {
              slug: this.warehouse.slug
            }
          },
          text: this.warehouse.name,
          exact: true
        });
      }
      return breadCrumbs;
    }
  },
  methods: {
    async findWarehouse() {
      if (this.warehouseList.length == 0) {
        let data = await AddressService.findWarehouse({
          region_id: this.selectedRegionId
        });
        this.warehouseList = data.warehouses;
      }
      this.warehouse = this.warehouseList.find(
        element => element.slug == this.$route.params.slug
      );
    }
  },
  mounted() {
    this.findWarehouse();
  },
  watch: {
    $route() {
      this.findWarehouse();
    }
  }
};
</script>
