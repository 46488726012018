var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warehouse-detail"},[_c('Breadcrumb',{attrs:{"items":_vm.breadCrumbs}}),_c('div',{staticClass:"grey lighten-3 py-6"},[_c('v-container',[_c('v-card',{key:_vm.warehouse.warehouseId,attrs:{"elevation":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","xl":"3"}},[_c('div',{staticClass:"pa-6 warehouse-detail-column"},[_c('h1',[_c('img',{attrs:{"src":"/location.svg","alt":"pin negozio"}}),_vm._v(_vm._s(_vm.warehouse.name)+" ")]),_c('div',{staticClass:"mb-4"},[_c('strong',[_vm._v(_vm._s(_vm.warehouse.address.address1)+", "+_vm._s(_vm.warehouse.address.addressNumber)+", "+_vm._s(_vm.warehouse.address.city)+" - ("+_vm._s(_vm.warehouse.address.province)+") "+_vm._s(_vm.warehouse.address.postalcode))])]),(_vm.warehouse.metaData.warehouse_info)?_c('div',[_c('strong',[_vm._v("Email: ")]),_c('a',{attrs:{"href":'mailto:' + _vm.warehouse.metaData.warehouse_info.EMAIL}},[_vm._v(_vm._s(_vm.warehouse.metaData.warehouse_info.EMAIL))])]):_vm._e(),(_vm.warehouse.metaData.warehouse_info)?_c('div',[_c('strong',[_vm._v("Email delivery: ")]),_c('a',{attrs:{"href":'mailto:' +
                      _vm.warehouse.metaData.warehouse_info.EMAIL_DELIVERY}},[_vm._v(_vm._s(_vm.warehouse.metaData.warehouse_info.EMAIL_DELIVERY))])]):_vm._e(),(_vm.warehouse.metaData.warehouse_info)?_c('div',[_c('strong',[_vm._v("Telefono: ")]),_c('a',{attrs:{"href":'tel:' + _vm.warehouse.metaData.warehouse_info.PHONE}},[_vm._v(_vm._s(_vm.warehouse.metaData.warehouse_info.PHONE))])]):_vm._e(),(_vm.warehouse.metaData.warehouse_info)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.warehouse.metaData.warehouse_info.SERVICES_DESCR)},on:{"click":_vm.clicked}}):_vm._e(),(_vm.warehouse.metaData.warehouse_info)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.warehouse.metaData.warehouse_info.OPENING_TIMES)},on:{"click":_vm.clicked}}):_vm._e(),_c('h4',[_vm._v("SERVIZI ATTIVI")]),(_vm.warehouse.metaData.warehouse_service)?_c('div',{staticClass:"d-flex flex-wrap"},[(_vm.warehouse.metaData.warehouse_service.DELIVERY)?_c('router-link',{staticClass:"pa-3",attrs:{"to":{ name: 'Page', params: { pageName: 'delivery' } },"title":"Servizio Delivery attivo in questo punto vendita"}},[_c('img',{attrs:{"src":"https://www.grosmarket.it/p/wp-content/uploads/2021/06/Delivery-1-45x45.png","alt":"Icona Servizio Delivery"}})]):_vm._e(),(_vm.warehouse.metaData.warehouse_service.CASH_CARRY)?_c('router-link',{staticClass:"pa-3",attrs:{"to":{
                    name: 'Page',
                    params: { pageName: 'cash-and-carry' }
                  },"title":"Servizio Cash & Carry attivo in questo punto vendita"}},[_c('img',{attrs:{"src":"https://www.grosmarket.it/p/wp-content/uploads/2021/06/CashAndCarry-45x45.png","alt":"Icona Servizio Cash & Carry"}})]):_vm._e(),(_vm.warehouse.metaData.warehouse_service.CLICK_COLLECT)?_c('router-link',{staticClass:"pa-3",attrs:{"to":{ name: 'Page', params: { pageName: 'clickcollect' } },"title":"Servizio Click & Collect attivo in questo punto vendita"}},[_c('img',{attrs:{"src":"https://www.grosmarket.it/p/wp-content/uploads/2021/06/ClickAndCollect-1-45x45.png","alt":"Icona Servizio Click & Collect"}})]):_vm._e(),(_vm.warehouse.metaData.warehouse_service.BUY_GO)?_c('router-link',{staticClass:"pa-3",attrs:{"to":{ name: 'Page', params: { pageName: 'buy-and-go' } },"title":"Servizio Buy & Go attivo in questo punto vendita"}},[_c('img',{attrs:{"src":"https://www.grosmarket.it/p/wp-content/uploads/2021/06/BuyAndGo-45x45.png","alt":"Icona Servizio Click & Collect"}})]):_vm._e(),(_vm.warehouse.metaData.warehouse_service.HORECA)?_c('router-link',{staticClass:"pa-3",attrs:{"to":{ name: 'Page', params: { pageName: 'horeca' } },"title":"Servizio Horeca Account attivo in questo punto vendita"}},[_c('img',{attrs:{"src":"https://www.grosmarket.it/p/wp-content/uploads/2021/06/Horeca-1-45x45.png","alt":"Icona Servizio Horeca Account"}})]):_vm._e()],1):_vm._e()])]),_c('v-col',{attrs:{"cols":"12","sm":"8","xl":"9"}},[(_vm.warehouse.warehouseId)?_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth, height: _vm.mapHeight }),attrs:{"center":{
                lat: _vm.warehouse.address.latitude,
                lng: _vm.warehouse.address.longitude
              },"zoom":15,"options":{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: false
              }}}):_vm._e()],1)],1)],1)],1)],1),_c('div',{},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-img',{attrs:{"src":"/img/contact-store.jpg"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('form-servizio-clienti',{attrs:{"showPrivacy":false,"warehouse-email":_vm.warehouse.metaData.warehouse_info.EMAIL}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }